import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";
import authRequest from "@src/helpers/auth-request";
import { navigate } from "gatsby";

export const SurveyContext = createContext({
	survey: null,
	loading: true,
	fetchSurvey: async () => {},
});

export const SurveyProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const [survey, setSurvey] = useState(null);
	const [loading, setLoading] = useState(false);

	const fetchSurvey = async (options = {}) => {
		const { latest = true } = options;
		setLoading(true);
		try {
			const surveyID = localStorage.getItem("SURVEY_ID");
			const resp = await authRequest({
				endPoint: latest ? `survey/${surveyID}` : "survey",
				params: { locale: i18n.language },
			});
			console.log("SURVEYRES: ", resp);
			if (resp?.response?.status === 404) {
				localStorage.removeItem("SURVEY_DONE");
				localStorage.removeItem("DNA_FOCUS");
				localStorage.removeItem("LIFESTYLE_FOCUS");
				localStorage.removeItem("DNA_UPLOADED");
				localStorage.removeItem("DNA_UPLOADED");
				localStorage.removeItem("SURVEY_ID");
				localStorage.removeItem("SURVEY_ANSWERS");
				localStorage.removeItem("BASICINFO_STEP");
				localStorage.removeItem("DIETARY_STEP");
				localStorage.removeItem("LIFEHABIT_STEP");
				localStorage.removeItem("DNA_STEP");

				localStorage.removeItem("SURVEY_STEP");
				localStorage.removeItem("DNA_STEP");
				localStorage.removeItem("LIFESTYLE_STEP");
				localStorage.removeItem("GOALS_STEP");

				navigate("/account/");
				return;
			}
			setSurvey(resp);
			return resp;
		} catch (error) {
			console.error("Failed to fetch survey:", error);
			return null;
		} finally {
			setLoading(false);
		}
	};

	const getSurvey = useCallback(fetchSurvey, [i18n.language]);

	useEffect(() => {
		// getSurvey();
	}, [getSurvey, i18n.language]);

	useEffect(() => {
		if (survey) console.log("Survey:", survey);
	}, [survey]);

	return (
		<SurveyContext.Provider
			value={{
				survey,
				loading,
				fetchSurvey,
			}}
		>
			{children}
		</SurveyContext.Provider>
	);
};
