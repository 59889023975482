import React from "react";
import { AppProvider } from "@src/hooks/use-app";
import { SurveyProvider } from "@src/hooks/use-survey";
import { I18nextProvider } from "react-i18next";
import i18next from "@src/i18n/config";

const RootElement = ({ children }) => {
	return (
		<I18nextProvider i18n={i18next}>
			<AppProvider>
				<SurveyProvider>{children}</SurveyProvider>
			</AppProvider>
		</I18nextProvider>
	);
};

export default RootElement;
