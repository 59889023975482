import React, { useContext } from "react";
import { AppContext, AppProvider } from "@src/context/app-context";

const useApp = () => {
	const context = useContext(AppContext);

	return context;
};

export { AppProvider, useApp };
