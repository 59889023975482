import { navigate } from "gatsby";
import makeRequest from "./make-request";

const authRequest = async ({
	headers = {},
	method,
	endPoint,
	params,
	data,
	urlString,
} = {}) => {
	try {
		const response = await makeRequest({
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				...headers,
			},
			method,
			endPoint,
			params,
			data,
			urlString,
		});

		return response;
	} catch (error) {
		console.log("Error: ", error);

		if (error?.response?.status === 401) {
			localStorage.removeItem("token");
			localStorage.removeItem("token_expiry");

			navigate("/login");
		}

		return error;
	}
};

export default authRequest;
