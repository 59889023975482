import React, { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeRequest from "@src/helpers/make-request";

export const AppContext = createContext({
	app: null,
	loading: true,
	getApp: () => {},
});

export const AppProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const [app, setApp] = useState(null);
	const [loading, setLoading] = useState(false);

	const getApp = useCallback(async () => {
		// setLoading(true);

		// await makeRequest({
		// 	endPoint: "",
		// 	params: {
		// 		locale: i18n.language,
		// 	},
		// }).then((resp) => {
		// 	setLoading(false);
		// });
		setLoading(false);
	}, [i18n.language]);

	useEffect(() => {
		// console.log("APP LOADED", app);
		getApp(); // loading it here cause double call - not sure why
	}, []);

	useEffect(() => {
		// if (app) console.log("APP:", app);
	}, [app]);

	return (
		<AppContext.Provider
			value={{
				app,
				loading,
				getApp,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
