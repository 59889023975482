/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/
 */

import React from "react";
import { Helmet } from "react-helmet";
import RootElement from "@src/components/root-element";

export const onRenderBody = (
	{ setHeadComponents, setHtmlAttributes, setBodyAttributes },
	pluginOptions
) => {
	const helmet = Helmet.renderStatic();
	setHtmlAttributes(helmet.htmlAttributes.toComponent());
	setBodyAttributes(helmet.bodyAttributes.toComponent());
	setHeadComponents([
		helmet.title.toComponent(),
		helmet.link.toComponent(),
		helmet.meta.toComponent(),
		helmet.noscript.toComponent(),
		helmet.script.toComponent(),
		helmet.style.toComponent(),
	]);
};
export const onPreRenderHTML = ({
	getHeadComponents,
	replaceHeadComponents,
}) => {
	const headComponents = getHeadComponents();

	headComponents.sort((x, y) => {
		if (x.props && x.props["data-react-helmet"]) {
			return -1;
		} else if (y.props && y.props["data-react-helmet"]) {
			return 1;
		}
		return 0;
	});

	replaceHeadComponents(headComponents);
};

export const wrapRootElement = ({ element }) => {
	return <RootElement>{element}</RootElement>;
};
