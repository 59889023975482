import axios from "axios";

const API_URL = process.env.API_URL;

const makeRequest = ({
	headers = {},
	method,
	endPoint,
	params,
	data,
	urlString,
} = {}) => {
	const urlBase = `${API_URL}/api/v1`;
	const url =
		urlString && urlString.trim() ? urlString : `${urlBase}/${endPoint}`;
	return axios({
		method,
		url,
		data,
		params,
		headers: {
			...headers,
		},
	})
		.catch((error) => {
			console.error("error", error);
			throw error;
		})
		.then((response) => response.data);
};

export default makeRequest;
