import i18next from "i18next";
import detector from "i18next-browser-languagedetector";

i18next.use(detector).init({
	// lng: "en",
	defaultLanguage: "en",
	fallbackLng: ["en", "zh-CN"],
	resources: {
		en: {
			translations: require("./locales/en.json"),
		},
		"zh-CN": {
			translations: require("./locales/zh-CN.json"),
		},
	},
	ns: ["translations"],
	defaultNS: "translations",
	returnObjects: true,
	debug: process.env.NODE_ENV === "development",
	interpolation: {
		escapeValue: false, // not needed for react!!
	},
	react: {
		useSuspense: false,
	},
});

i18next.languages = ["en", "zh-CN"];

export default i18next;
