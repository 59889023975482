import React, { useContext } from "react";
import { SurveyContext, SurveyProvider } from "@src/context/survey-context";

const useSurvey = () => {
	const context = useContext(SurveyContext);

	return context;
};

export { SurveyProvider, useSurvey };
